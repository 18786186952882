<template>
<div class="main">
    <div class="close" @click="back">
        <svg t="1646227260722" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2043" width="30" height="30">
            <path d="M512 128C300.8 128 128 300.8 128 512s172.8 384 384 384 384-172.8 384-384S723.2 128 512 128zM512 832c-179.2 0-320-140.8-320-320s140.8-320 320-320 320 140.8 320 320S691.2 832 512 832z" p-id="2044" fill="#515151"></path>
            <path d="M672 352c-12.8-12.8-32-12.8-44.8 0L512 467.2 396.8 352C384 339.2 364.8 339.2 352 352S339.2 384 352 396.8L467.2 512 352 627.2c-12.8 12.8-12.8 32 0 44.8s32 12.8 44.8 0L512 556.8l115.2 115.2c12.8 12.8 32 12.8 44.8 0s12.8-32 0-44.8L556.8 512l115.2-115.2C684.8 384 684.8 364.8 672 352z" p-id="2045" fill="#515151"></path>
        </svg>
    </div>
    <!-- <div class="nav" @click="navshow">
        <svg t="1646235552662" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2093" width="20" height="20">
            <path d="M888.838 319.775l-751.1 0c-23.944 0-43.339-19.553-43.339-43.646l0-40.012c0-24.093 19.395-43.645 43.339-43.645l751.1 0c23.923 0 43.334 19.554 43.334 43.645l0 40.012c-0.001 24.093-19.411 43.646-43.333 43.646l0 0zM888.838 587.509l-751.1 0c-23.944 0-43.339-19.533-43.339-43.64l0-39.998c0-24.115 19.395-43.647 43.339-43.647l751.1 0c23.923 0 43.334 19.533 43.334 43.647l0 39.998c-0.001 24.107-19.411 43.64-43.333 43.64l0 0zM888.838 876.17l-751.1 0c-23.944 0-43.339-19.532-43.339-43.627l0-40.017c0-24.093 19.395-43.641 43.339-43.641l751.1 0c23.923 0 43.334 19.548 43.334 43.641l0 40.017c-0.001 24.094-19.411 43.627-43.333 43.627l0 0z" p-id="2094" fill="#707070"></path>
        </svg>
    </div> -->

    <mavon-editor class="reader" :navigation="false" :value="blog" :subfield="false" :defaultOpen="'preview'" :toolbarsFlag="false" :editable="false" :scrollStyle="true" :ishljs="true">
    </mavon-editor>
</div>
</template>

<script scoped>
import url from "@/serviceAPI.config.js";
import "mavon-editor/dist/css/index.css";

export default {
    data() {
        return {
            blog: "",
        };
    },
    methods: {
        getedata(idnum) {
            this.$http
                .post(url.singledata, {
                    id: idnum,
                })
                .then((response) => {
                    // console.log(response.data.data.content);
                    this.blog = response.data.data.content;
                })
                .catch((error) => {
                    // console.log(error);
                    // ！！！出现错误怎么再次请求数据
                    this.getedata(idnum);
                });
        },
        back() {
            this.$destroy();
            this.$router.back(-1);

        },
        // navshow() {
        //     console.log(this.shownav)
        //    this.shownav = ! this.shownav
        // },
    },
    created() {
        this.getedata(this.$route.query.id);
        // console.log(this.$refs.reader)
        // this.readModel()
    },

};
</script>

<style scoped>
.main {
    position: relative;
}

.close {
    position: fixed;
    top: 2vh;
    left: 90vw;
    z-index: 99;
}

.nav {
    position: fixed;
    top: 2vh;
    left: 10vw;
    z-index: 99;
}

.reader {
    /* position: absolute; */
    z-index: 2 !important;
}

/* /deep/ .v-note-wrapper .v-note-panel .v-note-navigation-wrapper {
    left: 0;

    text-decoration: none !important;
    text-decoration-line: none !important;
}

/deep/ .v-note-navigation-close {
    position: relative;
    top: 2vh;
    float: right;
    color: #606266;
    font-size: 18px;
    cursor: pointer;
} */
.main>>>.v-note-wrapper {
    min-height: 100vh;
}
</style>
